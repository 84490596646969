import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IUserRole } from '@itorum/models';
import { PermissionRolesService } from "app/common/services/permission-roles.service";
import { AppConfigService } from './app-config.service';

export enum HeaderIds {
  Management,
  Expert,
  Catalog,
  Tasks,
  TechCards,
  OperationalLog,
  Conference,
  CustomCards,
  Monitors,
}

interface Level2Header {
  id?: HeaderIds;
  enabled?: boolean;
  includeMainPage?: boolean;
  link?: string;
  roles: any[];
  title: string;
}

interface Level1Header extends Level2Header {
  childRoutes?: Level2Header[];
  routerBindLink: string;
}

@Injectable()
export class PermissionService {
  private _roles: IUserRole[];
  private _permissions: Array<number>;

  private _pagesJSON = {
    management: [],
    users: [this.permissionRolesService.EUserRole.ADMINISTRATOR_KIP, this.permissionRolesService.EUserRole.MANAGER],
    add_user: [this.permissionRolesService.EUserRole.ADMINISTRATOR_KIP],
    user_details: [this.permissionRolesService.EUserRole.ADMINISTRATOR_KIP, this.permissionRolesService.EUserRole.MANAGER],
    my_technics: [this.permissionRolesService.EUserRole.REMOTE_EXPERT],
    glasses: [this.permissionRolesService.EUserRole.ADMINISTRATOR_KIP],
    monitoring: [this.permissionRolesService.EUserRole.ADMINISTRATOR_KIP],
    session_records: [
      this.permissionRolesService.EUserRole.TECHNIC,
      this.permissionRolesService.EUserRole.REMOTE_EXPERT,
      this.permissionRolesService.EUserRole.MANAGER,
      this.permissionRolesService.EUserRole.ADMINISTRATOR_KIP
    ],
    log: [this.permissionRolesService.EUserRole.ADMINISTRATOR_KIP, this.permissionRolesService.EUserRole.MANAGER],
    video: [this.permissionRolesService.EUserRole.ADMINISTRATOR_KIP, this.permissionRolesService.EUserRole.MANAGER]
  };

  public generatedHeader = [];
  public generateMainPage = [];
  public defaultHeaderInitial: Level1Header[];

  private flatHeaders: Level2Header[];

  constructor(
    private router: Router,
    public appConfigService: AppConfigService,
    private permissionRolesService: PermissionRolesService
  ) {
    this.defaultHeaderInitial = getDefaultInitial(this.appConfigService, this.permissionRolesService);
    this.flatHeaders = this.defaultHeaderInitial.reduce(
      (accum: Level2Header[], section: Level1Header) => {
        accum.push(section);
        section.childRoutes.forEach((childRoute: Level2Header) => {
          accum.push(childRoute);
        });
        return accum;
      },
      []
    );
  }

  set roles(roles: IUserRole[]) {
    this._roles = roles;
    this.permissions = roles.map((role: IUserRole) => role.role_id);
  }

  get roles(): IUserRole[] {
    return this._roles;
  }

  get pagesJSON(): unknown {
    return this._pagesJSON;
  }

  set permissions(permissions: Array<number>) {
    this._permissions = permissions;
    this.initHeader();
    setTimeout(() => {
      this.initMainPage();
    }, 0);
  }

  get permissions(): Array<number> {
    return this._permissions;
  }

  initHeader() {
    const sectionsHeader = this.defaultHeaderInitial.filter(section => {
      // eslint-disable-next-line no-prototype-builtins
      if (section.hasOwnProperty('enabled') && !section.enabled) {
        return false;
      }
      return (
        section.roles &&
        section.roles.some(role => {
          return this.permissions.indexOf(role) !== -1;
        })
      );
    });
    const finalHeader = JSON.parse(JSON.stringify(sectionsHeader));

    for (const section of finalHeader) {
      section.childRoutes = section.childRoutes.filter(childRoute => {
        // eslint-disable-next-line no-prototype-builtins
        if (childRoute.hasOwnProperty('enabled') && !childRoute.enabled) {
          return false;
        }
        return (
          childRoute.roles &&
          childRoute.roles.some(role => {
            return this.permissions.indexOf(role) !== -1;
          })
        );
      });
    }
    this.generatedHeader = finalHeader;
    // console.log('this.generatedHeader', this.generatedHeader);
  }

  initMainPage() {
    this.generateMainPage = [];
    const section = this.generatedHeader.filter(el => {
      return el.includeMainPage;
    });
    const subsections = [];
    for (let i = 0; i < this.generatedHeader.length; i++) {
      this.generatedHeader[i].childRoutes.map(el => {
        if (el.includeMainPage) {
          subsections.push(el);
        }
      });
    }
    this.generateMainPage = this.generateMainPage.concat(section, subsections);
  }

  checkForPermission(str: string): boolean {
    const page = str.replace('-', '_');

    return (
      this.permissions &&
      this.permissions.some(role => {
        return this.pagesJSON[page].includes(role);
      })
    );
  }

  navigateHome(): boolean {
    if (this.permissions && this.permissions.length) {
      switch (this.permissions[0]) {
        case this.permissionRolesService.EUserRole.ADMINISTRATOR_KIP:
          this.router
            .navigateByUrl('main/management/users')
            .catch(err => console.log(err));
          return true;
        case this.permissionRolesService.EUserRole.MANAGER:
          this.router
            .navigateByUrl('main/management/users')
            .catch(err => console.log(err));
          return true;
        case this.permissionRolesService.EUserRole.DEVELOPER:
          this.router
            .navigateByUrl('main/tasks/list')
            .catch(err => console.log(err));
          return true;
        case this.permissionRolesService.EUserRole.REMOTE_EXPERT:
          this.router
            .navigateByUrl('main/expert')
            .catch(err => console.log(err));
          return true;
        case this.permissionRolesService.EUserRole.TECHNIC:
          this.router
            .navigateByUrl('main/expert/session-records')
            .catch(err => console.log(err));
          return true;
      }
    }
    return false;
  }

  public getFeature(title: string): boolean {
    const searchedHeader = this.flatHeaders.find(
      header => header.title === title
    );
    if (!searchedHeader) {
      return false;
    }
    // eslint-disable-next-line no-prototype-builtins
    return searchedHeader.hasOwnProperty('enabled')
      ? searchedHeader.enabled
      : true;
  }
}

function getDefaultInitial(appConfigService: AppConfigService, permissionRolesService: PermissionRolesService) {
  return [
    {
      id: HeaderIds.Management,
      title: 'leftMenu.management.title',
      roles: [permissionRolesService.EUserRole.ADMINISTRATOR_KIP],
      routerBindLink: 'management',
      childRoutes: [
        {
          title: 'leftMenu.management.childs.users',
          enabled: true, // this for typing
          link: '/main/management/users',
          includeMainPage: true,
          roles: [permissionRolesService.EUserRole.ADMINISTRATOR_KIP]
        },
        {
          title: 'leftMenu.management.childs.glasses',
          link: '/main/management/glasses',
          roles: [permissionRolesService.EUserRole.ADMINISTRATOR_KIP]
        },
        {
          title: 'leftMenu.management.childs.monitoring',
          link: '/main/management/monitoring',
          includeMainPage: true,
          roles: [permissionRolesService.EUserRole.ADMINISTRATOR_KIP]
        },
        {
          title: 'leftMenu.management.childs.log',
          link: '/main/management/log',
          includeMainPage: true,
          roles: [permissionRolesService.EUserRole.ADMINISTRATOR_KIP]
        }
      ]
    },
    // {
    //   id: HeaderIds.Expert,
    //   title: 'leftMenu.expert.title',
    //   roles: [
    //     permissionRolesService.EUserRole.TECHNIC,
    //     permissionRolesService.EUserRole.REMOTE_EXPERT,
    //     permissionRolesService.EUserRole.MANAGER,
    //     permissionRolesService.EUserRole.ADMINISTRATOR_KIP
    //   ],
    //   routerBindLink: 'expert',
    //   enabled: true,
    //   childRoutes: [
    //     {
    //       title: 'leftMenu.expert.childs.myTechnics',
    //       enabled: true,
    //       link: '/main/expert/my-technics',
    //       includeMainPage: true,
    //       roles: [permissionRolesService.EUserRole.REMOTE_EXPERT]
    //     },
    //     {
    //       title: 'leftMenu.expert.childs.sessionRecords',
    //       link: '/main/expert/session-records',
    //       includeMainPage: true,
    //       roles: [
    //         permissionRolesService.EUserRole.TECHNIC,
    //         permissionRolesService.EUserRole.REMOTE_EXPERT,
    //         permissionRolesService.EUserRole.MANAGER,
    //         permissionRolesService.EUserRole.ADMINISTRATOR_KIP
    //       ]
    //     }
    //   ]
    // },
    {
      id: HeaderIds.Catalog,
      title: 'leftMenu.catalog.title',
      roles: [
        permissionRolesService.EUserRole.DEVELOPER
      ],
      includeMainPage: true,
      link: '/main/catalog/steps',
      routerBindLink: 'catalog',
      childRoutes: [
        {
          title: 'leftMenu.catalog.childs.objects',
          link: '/main/catalog/objects',
          roles: [
            permissionRolesService.EUserRole.DEVELOPER
          ]
        },
        {
          title: 'leftMenu.catalog.childs.organizations',
          link: '/main/catalog/organizations',
          roles: [
            permissionRolesService.EUserRole.DEVELOPER
          ]
        },
        {
          title: 'leftMenu.catalog.childs.types',
          link: '/main/catalog/types',
          roles: [
            permissionRolesService.EUserRole.DEVELOPER
          ]
        },
        // {
        //   title: 'leftMenu.catalog.childs.class',
        //   link: '/main/catalog/class',
        //   roles: [permissionRolesService.EUserRole.ADMINISTRATOR_KIP]
        // },
        {
            // title: appConfigService
            // ? 'leftMenu.catalog.childs.disciplines'
            // : 'leftMenu.catalog.childs.competences',
          title: 'leftMenu.catalog.childs.competences',
          link: '/main/catalog/competences',
          roles: [
            permissionRolesService.EUserRole.DEVELOPER
          ]
        },
        // {
        //   title: 'leftMenu.catalog.childs.zones',
        //   link: '/main/catalog/zones',
        //   roles: [
        //     permissionRolesService.EUserRole.ADMINISTRATOR_KIP,
        //     permissionRolesService.EUserRole.MANAGER
        //   ]
        // },
        // {
        //   title: 'leftMenu.catalog.childs.steps',
        //   link: '/main/catalog/steps',
        //   roles: [
        //     permissionRolesService.EUserRole.ADMINISTRATOR_KIP,
        //     permissionRolesService.EUserRole.MANAGER,
        //     permissionRolesService.EUserRole.DEVELOPER
        //   ]
        // }
      ]
    },
    // {
    //   id: HeaderIds.Tasks,
    //   title: 'leftMenu.tasks.title',
    //   roles: [
    //     permissionRolesService.EUserRole.MANAGER,
    //     permissionRolesService.EUserRole.DEVELOPER,
    //     permissionRolesService.EUserRole.TECHNIC,
    //     permissionRolesService.EUserRole.INSPECTION_MEMBER
    //   ],
    //   includeMainPage: true,
    //   link: '/main/tasks',
    //   routerBindLink: 'tasks',
    //   childRoutes: [
    //     {
    //       title: 'leftMenu.tasks.childs.templateTasks',
    //       link: '/main/template-tasks',
    //       roles: [
    //         permissionRolesService.EUserRole.MANAGER,
    //         permissionRolesService.EUserRole.DEVELOPER
    //       ]
    //     },
    //     {
    //       title: 'leftMenu.tasks.childs.list',
    //       link: '/main/tasks/list',
    //       roles: [
    //         permissionRolesService.EUserRole.MANAGER,
    //         permissionRolesService.EUserRole.DEVELOPER,
    //         permissionRolesService.EUserRole.TECHNIC,
    //         permissionRolesService.EUserRole.INSPECTION_MEMBER
    //       ]
    //     },
    //     {
    //       title: 'leftMenu.tasks.childs.registerDefects',
    //       link: '/main/tasks/register-defects',
    //       roles: [
    //         permissionRolesService.EUserRole.MANAGER,
    //         permissionRolesService.EUserRole.DEVELOPER
    //       ]
    //     }
    //   ]
    // },
    // {
    //   id: HeaderIds.TechCards,
    //   title: 'leftMenu.techCards.title',
    //   roles: [permissionRolesService.EUserRole.MANAGER, permissionRolesService.EUserRole.DEVELOPER],
    //   includeMainPage: true,
    //   link: '/main/techcards',
    //   routerBindLink: 'techcards',
    //   childRoutes: [
    //     {
    //       title: 'leftMenu.techCards.child.list',
    //       link: '/main/techcards/base',
    //       roles: [permissionRolesService.EUserRole.MANAGER, permissionRolesService.EUserRole.DEVELOPER]
    //     },
    //     {
    //       title: 'leftMenu.techCards.child.issued',
    //       link: '/main/techcards/authorized',
    //       roles: [permissionRolesService.EUserRole.MANAGER]
    //     },
    //     // {
    //     //   title: 'leftMenu.techCards.child.constructorTC', // todo fixed, not naming keys like reserve words!!!
    //     //   link: '/main/techcards/constructor',
    //     //   roles: [
    //     //     permissionRolesService.EUserRole.ADMINISTRATOR_KIP,
    //     //     permissionRolesService.EUserRole.INSPECTION_MEMBER,
    //     //     permissionRolesService.EUserRole.TECHNIC,
    //     //     permissionRolesService.EUserRole.DEVELOPER,
    //     //     permissionRolesService.EUserRole.MANAGER,
    //     //     permissionRolesService.EUserRole.REMOTE_EXPERT
    //     //   ]
    //     // }
    //     // {
    //     //     title: 'leftMenu.techCards.child.statistics',
    //     //     link: '/main/techcards/statistics',
    //     //     roles: [permissionRolesService.EUserRole.MANAGER]
    //     // },
    //     // {
    //     //   title: 'leftMenu.techCards.child.analytics',
    //     //   link: '/main/techcards/analytics',
    //     //   roles: [permissionRolesService.EUserRole.DEVELOPER]
    //     // }
    //   ]
    // },
    {
      id: HeaderIds.CustomCards,
      title: 'leftMenu.customCards.title',
      roles: [
        permissionRolesService.EUserRole.MANAGER,
        permissionRolesService.EUserRole.DEVELOPER,
        permissionRolesService.EUserRole.TECHNIC,
      ],
      includeMainPage: true,
      link: '/main/custom-cards',
      routerBindLink: 'custom-cards',
      childRoutes: [
        {
          title: 'leftMenu.customCards.child.list',
          link: '/main/custom-cards/custom-card-list',
          roles: [
            permissionRolesService.EUserRole.MANAGER,
            permissionRolesService.EUserRole.DEVELOPER,
            permissionRolesService.EUserRole.TECHNIC
          ]
        },
        {
          title: 'leftMenu.customCards.child.issued',
          link: '/main/custom-cards/custom-card-issued-list',
          roles: [
            permissionRolesService.EUserRole.MANAGER,
            permissionRolesService.EUserRole.DEVELOPER,
            permissionRolesService.EUserRole.TECHNIC
          ]
        },
        {
          title: 'leftMenu.customCards.child.job-template',
          link: '/main/custom-cards/job-template-list',
          roles: [
            permissionRolesService.EUserRole.TECHNIC,
            permissionRolesService.EUserRole.DEVELOPER,
            permissionRolesService.EUserRole.MANAGER,
            permissionRolesService.EUserRole.REMOTE_EXPERT
          ]
        },
        {
          title: 'leftMenu.customCards.child.remark-list',
          link: '/main/custom-cards/remark-list',
          roles: [
            permissionRolesService.EUserRole.TECHNIC,
            permissionRolesService.EUserRole.DEVELOPER,
            permissionRolesService.EUserRole.MANAGER,
            permissionRolesService.EUserRole.REMOTE_EXPERT
          ]
        },
        // {
        //   title: 'leftMenu.customCards.child.calendar-work',
        //   link: '/main/custom-cards/calendar',
        //   roles: [
        //     permissionRolesService.EUserRole.ADMINISTRATOR_KIP,
        //     permissionRolesService.EUserRole.INSPECTION_MEMBER,
        //     permissionRolesService.EUserRole.TECHNIC,
        //     permissionRolesService.EUserRole.DEVELOPER,
        //     permissionRolesService.EUserRole.MANAGER,
        //     permissionRolesService.EUserRole.REMOTE_EXPERT
        //   ]
        // },
        // {
        //   title: 'leftMenu.customCards.child.constructor',
        //   link: '/main/custom-cards/custom-card-constructor',
        //   roles: [
        //     permissionRolesService.EUserRole.ADMINISTRATOR_KIP,
        //     permissionRolesService.EUserRole.INSPECTION_MEMBER,
        //     permissionRolesService.EUserRole.TECHNIC,
        //     permissionRolesService.EUserRole.DEVELOPER,
        //     permissionRolesService.EUserRole.MANAGER,
        //     permissionRolesService.EUserRole.REMOTE_EXPERT
        //   ]
        // },
      ]
    },
    // {
    //   id: HeaderIds.OperationalLog,
    //   title: 'leftMenu.log.title',
    //   enabled: appConfigService.appConfig.operationalLog,
    //   roles: [
    //     permissionRolesService.EUserRole.MANAGER,
    //     // permissionRolesService.EUserRole.DEVELOPER,
    //   ],
    //   includeMainPage: true,
    //   link: '/main/log',
    //   routerBindLink: 'log',
    //   childRoutes: [
    //     {
    //       title: 'leftMenu.log.childs.log',
    //       link: '/main/log/log',
    //       roles: [
    //         permissionRolesService.EUserRole.MANAGER,
    //       ]
    //     },
    //     {
    //       title: 'leftMenu.log.childs.video',
    //       link: '/main/log/video',
    //       roles: [
    //         permissionRolesService.EUserRole.MANAGER,
    //       ]
    //     },
    //     {
    //       title: 'leftMenu.log.childs.catalog',
    //       link: '/main/log/catalog',
    //       roles: [
    //         permissionRolesService.EUserRole.MANAGER,
    //       ]
    //     }
    //   ]
    // },
    {
      id: HeaderIds.Conference,
      title: 'leftMenu.conference.title',
      includeMainPage: true,
      // enabled: true, // appConfigService.appConfig.conference
      roles: [
        permissionRolesService.EUserRole.TECHNIC,
        permissionRolesService.EUserRole.REMOTE_EXPERT
      ],
      link: '/main/conference',
      routerBindLink: 'conference',
      childRoutes: [
        {
          title: 'leftMenu.conference.conference-list.title',
          // enabled: true,
          link: '/main/conference/conference-list',
          roles: [
            permissionRolesService.EUserRole.TECHNIC,
            permissionRolesService.EUserRole.REMOTE_EXPERT
          ],
        },
        {
          title: 'leftMenu.conference.conference-history.title',
          // enabled: true,
          link: '/main/conference/conference-history',
          roles: [
            permissionRolesService.EUserRole.TECHNIC,
            permissionRolesService.EUserRole.REMOTE_EXPERT
          ],
        },
        {
          title: 'leftMenu.conference.publishers-online.title',
          // enabled: true,
          link: '/main/conference/users-online',
          roles: [
            permissionRolesService.EUserRole.TECHNIC,
            permissionRolesService.EUserRole.REMOTE_EXPERT
          ],
        },
        // {
        //   title: 'rxzu',
        //   // enabled: true,
        //   link: '/main/conference/rxzu',
        //   roles: [
        //     permissionRolesService.EUserRole.INSPECTION_MEMBER,
        //     permissionRolesService.EUserRole.TECHNIC,
        //     permissionRolesService.EUserRole.DEVELOPER,
        //     permissionRolesService.EUserRole.MANAGER,
        //     permissionRolesService.EUserRole.REMOTE_EXPERT
        //   ],
        // },
        // {
        //   title: 'Demo Page',
        //   // enabled: true,
        //   link: '/main/conference/demo-page',
        //   roles: [
        //     permissionRolesService.EUserRole.INSPECTION_MEMBER,
        //     permissionRolesService.EUserRole.TECHNIC,
        //     permissionRolesService.EUserRole.DEVELOPER,
        //     permissionRolesService.EUserRole.MANAGER,
        //     permissionRolesService.EUserRole.REMOTE_EXPERT
        //   ],
        // },
        // {
        //   title: 'leftMenu.conference.subconf.title',
        //   // enabled: true,
        //   link: '/main/conference/room',
        //   roles: [
        //     permissionRolesService.EUserRole.INSPECTION_MEMBER,
        //     permissionRolesService.EUserRole.TECHNIC,
        //     permissionRolesService.EUserRole.DEVELOPER,
        //     permissionRolesService.EUserRole.MANAGER,
        //     permissionRolesService.EUserRole.REMOTE_EXPERT
        //   ],
        // },
        // {
        //   'title': 'leftMenu.conference.subconf.title',
        //   enabled: true,
        //   link: '/main/conference',
        //   roles: [
        //     permissionRolesService.EUserRole.INSPECTION_MEMBER,
        //     permissionRolesService.EUserRole.TECHNIC,
        //     permissionRolesService.EUserRole.DEVELOPER,
        //     permissionRolesService.EUserRole.MANAGER,
        //     permissionRolesService.EUserRole.REMOTE_EXPERT
        //   ],
        // },
      ]
    },
    {
      id: HeaderIds.Monitors,
      title: 'leftMenu.monitors.title',
      includeMainPage: true,
      roles:[
        permissionRolesService.EUserRole.ADMINISTRATOR_KIP,
        permissionRolesService.EUserRole.MANAGER,
        permissionRolesService.EUserRole.DEVELOPER
      ],
      link: '/main',
      routerBindLink: 'main',
      childRoutes: [
        {
          title: 'leftMenu.monitors.device_monitor.title',
          link: '/main/device_monitor/1',
          roles: [
            permissionRolesService.EUserRole.ADMINISTRATOR_KIP,
            permissionRolesService.EUserRole.MANAGER,
            permissionRolesService.EUserRole.DEVELOPER
          ],
        },
        {
          title: 'leftMenu.monitors.map_monitor.title',
          link: '/main/map_monitor',
          roles: [
            permissionRolesService.EUserRole.ADMINISTRATOR_KIP,
            permissionRolesService.EUserRole.MANAGER,
            permissionRolesService.EUserRole.DEVELOPER
          ],
        },
      ]
    }
  ];
}

/**
 *
 *  Allowed Access:
 *  -------------------------------------------
 *  Управление - <Админ/Руководитель>
 *
 *      Пользователи - <Админ/Руководитель>
 *       Детали пользователя - <Админ/Руководитель>
 *       Новый пользователь - <Админ>
 *
 *      Очки - <Админ>
 *       Создать очки - <Админ>
 *       Детали очков - <Админ>
 *
 *      Мониторинг - <Админ>
 *       Мониторинг сессии юзера - <Админ>
 *
 *  -------------------------------------------
 *  Удаленный эксперт - <Техник/Удаленный Эксперт/Руководитель>
 *
 *      Мои специалисты - <Удаленный Эксперт>
 *
 *      Записи сеансов - <Техник/Удаленный Эксперт/Руководитель>
 *       Детали сеанса - <Техник/Удаленный Эксперт/Руководитель>
 *
 *  -------------------------------------------
 *  Справочники - <Админ/Руководитель/Разработчик>
 *
 *      Объекты - <Админ/Руководитель/Разработчик>
 *      Типы объектов - <Админ/Руководитель/Разработчик>
 *      Шаги инспектирования - <Админ/Руководитель/Разработчик>
 *
 *  -------------------------------------------
 *  Задачи - <Разработчик/Руководитель/Техник>
 *
 *      Задачи инспектирования - <Разработчик/Руководитель/Техник>
 *       Детали задачи - <Разработчик/Руководитель/Техник>
 */
